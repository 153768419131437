export var GLOBAL = {
    //url: 'https://santiagosystem.com/api/',
    //url2: 'https://santiagosystem.com/rest/',
    //url3: 'https://santiagosystem.com/'
    //url: 'https://adonaisystem.com/api/',
    //url2: 'https://adonaisystem.com/rest/',
    //url3: 'https://adonaisystem.com/'
    //url: 'https://fabti.pe/api/',
    //url2: 'https://fabti.pe/rest/',
    //url3: 'https://fabti.pe/'
    url: 'https://jhosepsystem.com/api/',
    url2: 'https://jhosepsystem.com/rest/',
    url3: 'https://jhosepsystem.com/'
    //url: 'https://glamourimportsystem.com/api/',
    //url2: 'https://glamourimportsystem.com/rest/',
    //url3: 'https://glamourimportsystem.com/'
}
